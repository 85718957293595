import React from 'react';
import {classes, style, vars} from './doppe-standard-lock-wizard-template.st.css';
import {Button} from '@wix/devzai-utils-react';
import {
    DoppeStandardLockWizardTemplate,
    DoppeStandardLockWizardTemplateMetadata
} from './doppe-standard-lock-wizard-template.metadata'
import {BymoPageStyleScope, BymoPageStyleScopeContext} from '../../bymo-page-style/bymo-page-style';
import {
    DoppeLockWizard,
    doppeLockWizardTemplateDefine
} from '../../../doppe-sdk/doppe-lock-wizard-template/doppe-lock-wizard-template';

export const DoppeStandardLockWizard = React.memo(function DoppeStandardLockWizard(props: DoppeLockWizard.Props<DoppeStandardLockWizardTemplate.TemplateProps>) {
    const {
        templateProps: {
            roundness,
        },
        enableAnimation = false,
        actionContext,
        unlockedCallback,
    } = props;

    return (
        <BymoPageStyleScopeContext.Provider
            value={BymoPageStyleScope.Card}
        >
            <div
                className={style(classes.root, {
                    enableAnimation: enableAnimation,
                })}
                style={{
                    [vars.roundness]: `${roundness}px`,
                }}
            >
                <Button
                    className={style(
                        classes.actionButton,
                        {},
                    )}
                    onClick={() => {
                        actionContext.startUnlocking(unlockedCallback)
                    }}
                >
                    Unlock
                </Button>
            </div>
        </BymoPageStyleScopeContext.Provider>

    )
});

export const DoppeStandardLockWizardTemplateData = doppeLockWizardTemplateDefine(
    DoppeStandardLockWizardTemplateMetadata,
    DoppeStandardLockWizard)