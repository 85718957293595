import {CookieConfigMap} from "@wix/devzai-utils-dom/src/cookie-storage-accessor/cookie-storage-accessor";

export type SupportecCookieNames = 'clientId' | 'sessionId' | 'consent-policy' | 'pagePath';
export const MaxAgeMaxValue = 2147483647;

export const SupportedCookies: CookieConfigMap<SupportecCookieNames> = {
    'clientId': {
        maxAge: MaxAgeMaxValue,
        sameSite: 'strict',
        consentType: 'anl',
    },
    'sessionId': {
        maxAge: 1800, // 30 minutes
        sameSite: 'strict',
        consentType: 'anl',
    },
    'consent-policy': {
        maxAge: MaxAgeMaxValue,
        sameSite: 'strict',
        consentType: 'anl',
    },
    'pagePath': {
        maxAge: 3600,
        sameSite: 'strict',
        consentType: 'anl',
        path: '/',
        domain: ['hopp.bio', 'hopp.to', 'bymo.io', 'doppe-viewer-sec.com']
    }
} as const;
