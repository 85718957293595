import type {DoppeActionType, DoppeActionTypeMetadata} from '../doppe-action-types/doppe-action-type';
import {assertType, enumIsEnumValue, enumReduce, Values} from '@wix/devzai-utils-common';
import {assertWixMediaResource, WixMediaResource} from '@wix/devzai-common-wix';
import {assertDoppeHideableValue, DoppeHideableValue, doppeHideableValueCreateHidden} from './doppe-hideable-value';
import {DoppeActionWidgetOpeningMode} from './doppe-action-widget-opening-mode';
import {DoppeActionListItemClickBehaviour} from './doppe-action-list-item-click-behaviour';

export const DoppeDtoActionViewType = {
    ActionButton: 'ActionButton',
    BannerButton: 'BannerButton',
    InPage: 'InPage',
    InPageFrameless: 'InPageFrameless'
} as const;

export type DoppeDtoActionViewType = Values<typeof DoppeDtoActionViewType>;

export const DoppeDtoActionPremiumViewType = enumReduce(DoppeDtoActionViewType, [
    DoppeDtoActionViewType.BannerButton,
    DoppeDtoActionViewType.InPageFrameless
]);

type DoppeDtoActionPremiumViewType = Values<typeof DoppeDtoActionPremiumViewType>;

export interface DoppeDtoAction<SETTINGS extends {} = any> {
    id: string;
    actionTypeId: DoppeActionType.Id;
    name: string;
    callToActionDescription: DoppeHideableValue<string>;
    enabled: boolean;
    icon: DoppeHideableValue<WixMediaResource | null>;
    bannerMedia: WixMediaResource | null;
    settings: SETTINGS;
    widgetOpeningMode: DoppeActionWidgetOpeningMode;
    viewType?: DoppeDtoActionViewType;
    showActionHeader?: boolean;
    isDummyAction?: boolean;
    tag?: string;
    searchSettings: DoppeDtoAction.SearchSettings;
    lockSettings: DoppeDtoAction.LockSettings;

}

const defaultSearchSettings = {
    indexInSearch: true,
    indexInSearchType: DoppeActionListItemClickBehaviour.PreviewItem,
    keyword: doppeHideableValueCreateHidden(''),
    title: doppeHideableValueCreateHidden(''),
    description: doppeHideableValueCreateHidden(''),
    image: doppeHideableValueCreateHidden(null)
}

// TODO: Why viewType doesn't have a default value?
const doppeDtoActionDefaultProps = {
    widgetOpeningMode: DoppeActionWidgetOpeningMode.Inline,
    icon: null,
    bannerMedia: null,
    tag: undefined,
    searchSettings: defaultSearchSettings,
    lockSettings: {
        lockActions: [],
        enableLock: false,
    },
    callToActionDescription: doppeHideableValueCreateHidden('Description'),
}

const defaultSearchListItemsSettings = {
    indexItemsInSearchType: DoppeActionListItemClickBehaviour.PreviewItem,
    indexItemsInSearch: true,
}

export type DoppeDtoActionDefaultPropsNames = keyof typeof doppeDtoActionDefaultProps;

export function doppeDtoActionGetDefaultProps () {
    return doppeDtoActionDefaultProps;
}

export function doppeDtoActionGetDefaultSearchListSettingsProps () {
    return defaultSearchListItemsSettings;
}

export function doppeDtoActionGetDefaultSearchSettingsProps () {
    return defaultSearchSettings;
}



export namespace DoppeDtoAction {
    export type Id = DoppeDtoAction['id'];

    export type SearchSettings = {
        indexInSearch: boolean;
        indexInSearchType: DoppeActionListItemClickBehaviour;
        keyword: DoppeHideableValue<string>;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<string>;
        image: DoppeHideableValue<WixMediaResource | null>;
    }

    export type LockSettings = {
        lockActions: DoppeDtoAction<any>[];
        enableLock: boolean;
    }

    export type ListSearchSettings = {
        indexItemsInSearch: boolean;
        indexItemsInSearchType: DoppeActionListItemClickBehaviour;
    }

    export type ActionListItem<SETTINGS extends {}> = {
        title: string;
        image: WixMediaResource | null;
        ribbon: DoppeHideableValue<string>
        couponCode: DoppeHideableValue<string>;
        action: DoppeDtoAction<SETTINGS>;
        itemId: string;
        url: string | null;
    }
}

export function assertDoppeDtoAction(action: DoppeDtoAction): asserts action is DoppeDtoAction;
export function assertDoppeDtoAction(action: unknown): asserts action is DoppeDtoAction;
export function assertDoppeDtoAction(action: unknown): asserts action is DoppeDtoAction {
    assertType<DoppeDtoAction<{}>>(action, assert => {
        assert.isObject({
            id: assert => assert.isString(),
            actionTypeId: assert => assert.isString(),
            name: assert => assert.isString(),

            enabled: assert => assert.isBoolean(),
            widgetOpeningMode: assert => assert.isString(),
            callToActionDescription: assert => assert.isUnion(
                assert => assert.isString(),
                assert => assert.usingAssertionFunction(value => {
                    return assertDoppeHideableValue<string>(value, assert => assert.isString())
                }),
            ),
            icon: assert => assert.usingAssertionFunction(value => {
                assertDoppeHideableValue<WixMediaResource | null>(value, assert => {
                    assert.isUnion(
                        assert => assert.isNull(),
                        assert => assert.usingAssertionFunction(assertWixMediaResource)
                    )
                })
            }),
            bannerMedia: assert => assert.isUnion(
                assert => assert.isNull(),
                assert => assert.usingAssertionFunction(assertWixMediaResource)
            ),
            settings: assert => assert.isObject({}, {
                assertNoUnknownKeys: false
            }),
            viewType: assert => assert.optional.isOneOfValues(Object.values(DoppeDtoActionViewType)),
            showActionHeader: assert => assert.optional.isBoolean(),
            isDummyAction: assert => assert.optional.isBoolean(),
            tag: assert => assert.optional.isString(),
            lockSettings: assert => assert.isAny(),
            searchSettings: assert => assert.isAny(),
            // image: assert => assert.usingAssertionFunction(value => {
            //     assertDoppeHideableValue<WixMediaResource | null>(value, assert => {
            //         assert.isUnion(
            //             assert => assert.isNull(),
            //             assert => assert.usingAssertionFunction(assertWixMediaResource)
            //         )
            //     })
            // }),
            // indexInSearch: assert => assert.isBoolean(),
            // indexInSearchType: assert => assert.isString(),
            // keyword: assert => assert.usingAssertionFunction(value => {
            //     return assertDoppeHideableValue<string>(value, assert => assert.isString())
            // }),
            // title: assert => assert.usingAssertionFunction(value => {
            //     return assertDoppeHideableValue<string>(value, assert => assert.isString())
            // }),
            // description: assert => assert.usingAssertionFunction(value => {
            //     return assertDoppeHideableValue<string>(value, assert => assert.isString())
            // }),
        }, {
            // TODO: It's better to remove all the unknown keys of an action using a migration.
            assertNoUnknownKeys: false
        })
    })
}

export function doppeActionIsOfType<T extends {}> (
    doppeAction: DoppeDtoAction,
    doppeActionType: DoppeActionTypeMetadata<T>
) : doppeAction is DoppeDtoAction<T> {
    return doppeAction.actionTypeId === doppeActionType.id;
}

export function doppeActionResolveSettings<T extends {}> (
    doppeAction: DoppeDtoAction<T>,
    actionTypeMetadata: DoppeActionTypeMetadata<T>
) {
    return actionTypeMetadata.resolveActionSettings(doppeAction)
}

export function doppeDtoActionShouldShowActionHeader (action: DoppeDtoAction) {
    return (
        action.showActionHeader !== undefined &&
        (action.viewType === DoppeDtoActionViewType.InPage || action.viewType === DoppeDtoActionViewType.InPageFrameless)
    ) ? action.showActionHeader : true;
}

export function doppeDtoActionViewTypeIsDisplayedAsButton (viewType: DoppeDtoActionViewType | undefined) {
    // Note: We compare also to undefined because the default view type is ActionButton
    return viewType === undefined || viewType === DoppeDtoActionViewType.ActionButton || viewType === DoppeDtoActionViewType.BannerButton;
}

export function doppeDtoActionViewTypeIsPremium (viewType: DoppeDtoActionViewType | undefined) : viewType is DoppeDtoActionPremiumViewType {

    const normalizedViewType = viewType ?? DoppeDtoActionViewType.ActionButton;

    return enumIsEnumValue(DoppeDtoActionPremiumViewType, normalizedViewType);
}